
import React, { useState } from 'react'
import { send } from 'emailjs-com'



const ContactFormAgente = () => {

      const [sender_name, set_sender_name] = useState('');
      const [sender_lastname, set_sender_lastname] = useState('');
      const [sender_email, set_sender_email] = useState('');
      const [sender_tel, set_sender_tel] = useState('');
      const [message, set_message] = useState('');
      const handleName = (e) => {
        set_sender_name(e.target.value)
      }
      const handleLastName = (e) => {
        set_sender_lastname(e.target.value)
      }
      const handleMail = (e) => {
        set_sender_email(e.target.value)
      }
      const handleTel = (e) => {
        set_sender_tel(e.target.value)
      }            
      const handleMEssage = (e) => {
        set_message(e.target.value)      
      }      
      //esto es del contact original
      const [error, setError] = useState(false);
      const [messages, setMessages] = useState("");

      const sendMail = (e) => {
        e.preventDefault();
        send(
            'service_aqwm4cb',
            'template_rgvqc1c',
            {sender_name, sender_lastname, sender_email, sender_tel, message},
            'zuBvNEbmCJ3q_DcyV'
        )
        .then((response) => {
            console.log('Message sent succesfully', response.status, response.text)
        })
        .catch((err) => {
            console.log('Failed', err);
        })

        //esto es del contact original

        if (!sender_name) {
          setError(true);
          setMessages("Name is required");
        } else if (!sender_lastname) {
          setError(true);
          setMessages("Email is required");
        } else if (!sender_email) {
          setError(true);
          setMessages("Subject is required");
        } else {
          setError(false);
          setMessages("You message has been sent!!!");
        }        
        //

        set_sender_name('');
        set_sender_lastname('');
        set_sender_email('');
        set_sender_tel('');
        set_message('');
      }

      //esto es del contact original
      const handleAlerts = () => {
        if (error && messages) {
          return <div className="alert alert-danger mt-4">{messages}</div>;
        } else if (!error && messages) {
          return <div className="alert alert-success mt-4">{messages}</div>;
        } else {
          return null;
        }
      };

      return (
          <div className="contact-form-component">
            
            
            <form onSubmit={sendMail} className="contactForm">

                <div className="row">
                    
                    <div className="col-12" >

                        <div className="row">

                          <div className="col-12 col-sm-12 col-md-6" >  
                            
                             <input type="text" name="sender_name" value={sender_name} onChange={handleName} required placeholder="Nombre" />
                             <input type="text" name="sender_lastname" value={sender_lastname} onChange={handleLastName} required placeholder="Apellidos" />
                           </div>
                           <div className="col-12 col-sm-12 col-md-6" >  
                             <input type="email" name="sender_email" value={sender_email} onChange={handleMail} required placeholder="E-mail" />
                             <input type="tel" name="sender_tel" value={sender_tel} onChange={handleTel} required placeholder="Télefono" />
                           </div>
                        </div>
                    </div>

                     <div className="col-12 textarea-wrap" >   
                        <div className="contact-form row">
                            <textarea name="message" value={message} onChange={handleMEssage} placeholder="Cuéntanos un poco sobre tu proyecto" />
                            <button type="submit" placeholder="Submit" value="ENVIAR  "> ENVIAR </button>
                            
                        </div>
                        
                    </div>
                   
                </div>
                {handleAlerts()} 
                </form>
          </div>

      )
  }

export default ContactFormAgente;

