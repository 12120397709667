import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import "./css/default.css";
import "./css/layout.css";
import "./css/fonts.css";


import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Agente from "./pages/Agente";
import Cleankind from "./works/Cleankind";
import Nudamarket from "./works/Nudamarket";
import Aurum from "./works/Aurum";
import Nomads from "./works/Nomads";
import Yogaone from "./works/Yogaone";
import Goikid from "./works/Goikid";
import Gsd from "./works/Gsd";
import Collabs from "./pages/Collabs";
import Privacy from "./pages/Privacy";
import Inolvidables from "./works/Inolvidables";


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Homepage} exact />


        {/* For Multipage */}
        <Route path="/about" component={About} exact />
        <Route path="/agente" component={Agente} exact />
        <Route path="/cleankind" component={Cleankind} exact />
        <Route path="/nudamarket" component={Nudamarket} exact />
        <Route path="/aurum" component={Aurum} exact />
        <Route path="/nomads" component={Nomads} exact />
        <Route path="/yogaone" component={Yogaone} exact />
        <Route path="/goikid" component={Goikid} exact />
        <Route path="/gsd" component={Gsd} exact />
        <Route path="/collabs" component={Collabs} exact />
        <Route path="/inolvidables" component={Inolvidables} exact />
        <Route path="/privacy-policy" component={Privacy} exact />

      </Switch>
    </BrowserRouter>
  );
}

export default App;
