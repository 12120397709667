import React, { useEffect, useRef } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";
import "../js/videoscroll.js";



function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const videoEl1 = useRef(null);

  const attemptPlay = () => {
    videoEl1 &&
      videoEl1.current &&
      videoEl1.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

window.addEventListener('load', videoScroll);
window.addEventListener('scroll', videoScroll);

function videoScroll() {

  if ( document.querySelectorAll('video').length > 0) {
    var windowHeight = window.innerHeight,
        videoEl = document.querySelectorAll('video');

    for (var i = 0; i < videoEl.length; i++) {

      var thisVideoEl = videoEl[i],
          videoHeight = thisVideoEl.clientHeight,
          videoClientRect = thisVideoEl.getBoundingClientRect().top;

      if ( videoClientRect <= ( (windowHeight) - (videoHeight*.5) ) && videoClientRect >= ( 0 - ( videoHeight*.5 ) ) ) {
        thisVideoEl.play();
      } else {
        thisVideoEl.pause();
      }

    }
  }

}


  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <img src='images/portfolio/details/5/yot.gif' alt='agencia digital creativa, desrrollo de webs y de apps, branding' />
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row details-container">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
              <li> Web Design </li>
              <li> Art Direction</li>
              <li> Copywriting & Story telling </li>                 
              <li> Photography</li>
              <li> Social Media</li>
              <li> <a href="https://yogaoneterrassa.com/" target="_blank" rel="noreferrer"> > Visit site</a></li>
            </ul>              
          </div>

          <div className="col-12 col-sm-12 col-md-7 ">
            <p>YogaOne Terrassa is an online yoga, fitness, and wellness platform that we designed to helps users find their best version of themselves through practice from anywhere and at any time. 
            With our team's help, we devised an online platform that could function as a yoga studio at home and help customers continue practicing.</p>
            <p>To ensure a seamless user experience, we focused on web design and UIX design. The platform offers a private library with audiovisual content that members can segment by intensity, style, 
            and time. Additionally, members can practice with the center's teachers and recognized instructors.</p>
            <p> Art direction and photography direction were aimed at creating a clean design with storytelling and copywriting that showcased the platform's unique features. 
            Finally, we created a launch campaign that focused on a tribal concept where people could feel they belong to this yogui comunity as well as promoting the platform's accessibility and flexibility.</p>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="TwentypxTop"> </div>
        

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 "><img src='images/portfolio/details/5/yogaone1.jpg' alt='agencia digital creativa desarrollo web y app ecommerce branding' /></div>
          <div className="col-12 col-sm-12 col-md-6 "><img src='images/portfolio/details/5/yogaone2.jpg' alt='agencia digital creativa,desarrollo web y app ecommerce branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10 "><img src='images/portfolio/details/5/yogaone3.jpg' alt='agencia digital creativa desarrollo web y app ecommerce branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpaceMedium2">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 imageAbsoluteBottom"><img src='images/portfolio/details/5/yogaone4.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-6 imageAbsoluteBottom"><img src='images/portfolio/details/5/yogaone5.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10 ">
            <video 
              id="videoEl"
              style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
              
              loop
              muted
              controls
              poster="images/portfolio/details/5/yogaone3.jpg"
              alt="Agencia digital creativa Barcelona"
              src={`${process.env.PUBLIC_URL}/images/portfolio/details/5/yot_campana.mp4`}
              ref={videoEl1}
            />

          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
      </div>
        <div className="row rowGridSpaceMedium2">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/5/yogaone7.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/5/yogaone8.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>    
        <div className="row rowGridSpaceMedium2">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/5/yogaone9.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/5/yogaone10.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>              
      <MiddleMenu />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      
    </div>





  );
}

export default Homepage;
