import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import HeaderInner from "../components/Layout/Header-inner";
import MiddleMenuInner from "../components/Sections/MiddleMenu-inner";
import ContactFormES from "../components/Sections/Contact-form-agente";
import $ from 'jquery'


$(document).ready(function() {

  //$('.about-inner-wrapper').parent('div').parent('body').addClass('blackBody');  
});



function DemopageAgente() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<div className="agente-inner-wrapper ">
   <HeaderInner />
   <MiddleMenuInner />
   <div className="" id="agente">
      <div className="row agenteTopWrap">
         <div className="col-12 col-sm-12 col-md-2"></div>
         <div className="col-12 col-sm-12 col-md-8">
            <h1>Somos agentes digitalizadores del Programa Acelera PYME
            </h1>
            <p>
               Colaboramos con el programa Acelera Pyme. Te ayudamos a construir el mundo digital de tu marca, ofreciendo asesoramiento e implementación de herramientas de comunicación digital.

            </p>
         </div>
         <div className="col-12 col-sm-12 col-md-2"></div>
      </div>
   </div>

   <div className="" id="">
      <div className="row agenteTopWrap">
         <div className="col-12 col-sm-12 col-md-2"></div>
         <div className="col-12 col-sm-12 col-md-8">
             <img className="icon-services logo-digitalizadores" src='images/logo-digitalizadores.png' alt='Agentes Digitalizadores' />
         </div>
         <div className="col-12 col-sm-12 col-md-2"></div>
      </div>
   </div>

   {/*   ABOUT*/}
   <div className="row border-black no-border-bottom">
      <div className="col-12 col-md-2 col-sm-12"></div>
      <div className="col-12 col-md-3 col-sm-12">
         <h2> ¿Qué es el programa ‘Kit Digital’? </h2>
      </div>
      <div className="col-12 col-md-5 col-sm-12">
         <p><strong>Acelera PYME</strong> es un programa de ayudas para la digitalización de pequeñas empresas, microempresas y personas en situación de autoempleo, dentro del <strong>Programa Kit Digital</strong> impulsado con los fondos europeos “Next Generation EU” dentro del Plan de Recuperación Transformación y Resiliencia. El artículo 9 de la Orden HFP/1030/2021, de 29 de septiembre, aprobada por el Ministerio de Asuntos Económicos y Transformación Digital, establece la configuración del sistema de gestión del Plan de Recuperación, Transformación y Resiliencia y las bases reguladoras del Programa Kit Digital.</p> 
         <p>Gracias a estas ayudas tu empresa podrá ser más competitiva y avanzar en su transformación digital.</p>
         

      </div>
      <div className="col-12 col-md-2 col-sm-12"></div>
   </div>
   {/* TEAM */}
   <div className="row services-wrap border-black">
      <div className="col-12 col-sm-12 col-md-2"></div>
      <div className="col-12 col-sm-12 col-md-3">
         <h2>¿De qué depende la cuantía de la subvención? </h2>
      </div>
      <div className="col-12 col-sm-12 col-md-5">

         <p>Según el tamaño de tu empresa, varía el importe máximo que puedes recibir, te los detallamos a continuación:</p>

         <p><strong>Segmento I</strong><br/>De 10 a 49 empleados → hasta 12.000 €</p>
         <p><strong>Segmento II</strong><br/>De 3 a 9 empleados → hasta 6.000 €</p>
         <p><strong>Segmento III</strong><br/>De 0 a 2 empleados y autónomos → hasta 3.000 €</p>
         
      </div>
      <div className="col-12 col-sm-12 col-md-2"></div>
   </div>





   {/* de que depende */}

   <div className="row services-wrap border-black">
      <div className="col-12 col-sm-12 col-md-2"></div>
      <div className="col-12 col-sm-12 col-md-3">
         <h2>¿Cómo podemos ayudarte a digitalizar tu negocio? </h2>
      </div>
      <div className="col-12 col-sm-12 col-md-5">
         <p> Como agentes digitalizadores oficiales del programa Acelera Pyme, una vez recibas la subvención, puedes contratar con nosotros las siguientes soluciones:</p>
         <br/>
         <div className="separadorBloques"></div>



         <p><strong>Sitio web y presencia en internet Diseño y Desarrollo de Páginas Web</strong></p>

         <p>Abordamos cualquier tipo de proyecto digital web. Determinaremos la mejor opción para la empresa beneficiaria y lo llevaremos a cabo enfocados siempre a conseguir los mejores resultados.</p>

         <p><strong>Servicio destinado a:</strong></p>
         <ul>
           <li>Empresas de entre 0 y 50 empleados.</li>
           <li>Microempresas de entre 0 y 10 empleados.</li>
           <li>Personas en Situación de Autoempleo.</li>
         </ul>

         <p><strong>Desde 2.000€</strong></p>

         <div className="separadorBloques"></div>


         <p><strong>Comercio Electrónico  Diseño y Desarrollo de Tiendas Online o Páginas de Cobro Online</strong></p>

         <p>Servicios de creación de una tienda online de compraventa de productos y/o servicios que utilice medios digitales para su intercambio.</p>

         <p><strong>Servicio destinado a:</strong></p>
         <ul>
           <li>Empresas de entre 0 y 50 empleados.</li>
           <li>Microempresas de entre 0 y 10 empleados.</li>
           <li>Personas en Situación de Autoempleo.</li>
         </ul>

         <p><strong>Desde 2.000€</strong></p>


         <div className="separadorBloques"></div>

         <p><strong>Presencia avanzada en internet Posicionamiento Web SEO</strong></p>

         <p>Optimizaremos tu sitio web para que aparezcas en las primeras posiciones de Google para las búsquedas más relevantes para tu negocio. Realizaremos el análisis de las palabras clave y la competencia para encontrar el nicho de mercado que te permita ser importante a nivel digital.</p>

         <p><strong>Servicio destinado a:</strong></p>
         <ul>
           <li>Empresas de entre 0 y 50 empleados。</li>
           <li>Microempresas de entre 0 y 10 empleados。</li>
           <li>Personas en Situación de Autoempleo。</li>
         </ul>

         <p><strong>Desde 2.000€</strong></p>



         
      </div>
      <div className="col-12 col-sm-12 col-md-2"></div>
   </div>










   {/*   SERVICES*/}
   <div className="row border-black services-wrap agente-form">
      <div className="col-12 col-sm-12 col-md-2"></div>
      <div className="col-12 col-sm-12 col-md-3">
         <h2> ¡Es hora de digitalizar tu negocio! Escríbenos para más información. </h2>

      </div>
      <div className="col-12 col-sm-12 col-md-5">
         <p>Desde DWO, como agente digitalizador aprobado, te ayudamos a construir el mundo digital de tu marca, utilizando una combinación única de contenido de marca y estrategia digital.

         </p> <p>Rellena el siguiente formulario y realizaremos un estudio gratuito y sin compromiso, de tu proyecto: </p>

         <ContactFormES />
      </div>
      <div className="col-12 col-sm-12 col-md-2"></div>
   </div>




   {/*   FOOTER */}
   <div className="row border-black no-border-top footerWrap ">
      <div className="col-12 col-sm-12 col-md-2"></div>
      <div className="col-12 col-sm-12 col-md-8">
         <div className="row rowFooterImg">
            <div className="col-12 col-sm-12 col-md-12"><img className="icon-services" src='images/logo-digitalizadores.png' alt='Agentes Digitalizadores' /></div>
         </div>
      </div>
      <div className="col-12 col-sm-12 col-md-2"></div>
   </div>
</div>
  );
}

export default DemopageAgente;

