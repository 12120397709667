import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";






function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">  <img src='images/portfolio/details/2/nuda-desarrollo-wen-app-agencia-creativa-digital.gif' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowDescription">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
                  <li> Branding </li>
                  <li> Web Design </li>
                  <li> Ecommerce </li>
                  <li> Copywriting </li>
                  <li> Art Direction</li>
                  <li> Photography </li>
                  <li> Social Media </li>                 
                  <li> Signage</li>
                  <li> <a href="http://www.nudamarket.com" target="_blank" rel="noreferrer"> > Visit site</a></li>
            </ul>              
          </div>
          <div className="col-12 col-sm-12 col-md-7 ">
            <p> The Gracia neighborhood hides this beautiful "zero waste" store, with a Scandinavian look and a wide variety of bulk and organic products. Created by three young entrepreneurs eager to change the world, 
            who through the slogan "Be imperfectly sustainable" remind us that every small change, no matter how small, is positive; without obsessions or frustrations.</p>

            <p> We are thrilled to have partnered with this beautiful project and to help them establish their online presence.  In this case,  we handled their branding and ecommerce needs. 
            We focused on creating a user-friendly ecommerce platform that would make shopping for sustainable and eco-friendly products a breeze. We also organized a professional photo shoot to capture the store's essence and products in the best possible way. 
            </p>

            <p> For their social media presence, we developed a comprehensive social media strategy to help the shop reach a wider audience and promote their values of sustainability and eco-friendliness.</p>
          </div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/2/nuda1.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/2/nuda2.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">  <img src='images/portfolio/details/2/nuda3.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowGridSpaceMedium2">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/2/nuda4.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/2/nuda5.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">  <img src='images/portfolio/details/2/nuda6.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpaceMedium2">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/2/nuda7.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/2/nuda8.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/2/nuda9.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/2/nuda10.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

      <MiddleMenu />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      
    </div>
    </div>





  );
}

export default Homepage;
