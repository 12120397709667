import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";






function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10"> <img src='images/portfolio/details/3/Aurum1.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowDescription">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
              <li> Branding </li>
              <li> Web Design </li>
              <li> Web Development  </li>
              <li> Art Direction</li>
              <li> Social Media</li>
              <li> <a href="https://aurumimmobles.com/" target="_blank">Visit website</a></li>
            </ul>              
          </div>
          <div className="col-12 col-sm-12 col-md-7 ">

            <p>We are long-term partners with Aurum Immobles, a leading construction company based in Sant Cugat del Vallès. This time we've completely redesigned their website with a minimalist design that prioritizes the use of high-quality pictures. The objective of the project was to create a visually stunning online presence that accurately represents the company's exceptional work and showcases their projects in the best possible light. </p>
            <p>We focused on incorporating stunning visuals into the design to create a clean and modern look. We strategically placed high-quality images throughout the website to give visitors an immediate impression of Aurum Immobles' expertise and attention to detail.</p>

            <p>In addition to the website redesign, we manage the company's social media since 2021, including their Instagram account. We developed an effective social media strategy that aligns with Aurum Immobles' brand values and targets their key audience. We use engaging content, eye-catching visuals, and a consistent tone of voice to create a social media presence that is both informative and visually captivating.</p>


          </div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 "><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-6"><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web-2.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10"><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web-3.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web-4.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-5"><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web-5.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-7"><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web-6.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-4"></div>
        </div>
                <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10"><img src='images/portfolio/details/3/aurum-rediseno-web-diseno-web-7.jpg' alt='agencia creativa, desarrollo web, diseño web, branding e imagen de marca, agencia creativa digital' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>    
      </div>
      <MiddleMenu />


      
    </div>




  );
}

export default Homepage;
