import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";




function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10"> <img src='images/portfolio/details/1/Clean_Kind.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowDescription">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
              <li> Branding </li>
              <li> Web Design </li>
               <li> Web Development (In progress) </li>
              <li> Copywriting </li>
              <li> Art Direction</li>
              <li> Photography </li>
            </ul>              
          </div>
          <div className="col-12 col-sm-12 col-md-7 ">
            <p>Clean & Kind is a Zero Waste project based in Barcelona, with a focus in organic, compastable and promixity products. Their mission is making buying without plastic in a daily basis easy and accessible to anyone.</p>
            <p>They came to us without branding nor website, something that always makes us very happy :) They wanted a clean and simple but eye catching branding and an 
                accessible and user-friendly ecommerce. We loved the idea since this alignes with the simplicity of bulk buying. </p>
            <p> So we did go for a typographic and minimalist branding and a very clean and user friendly ecommerce where the product was king and navigating and buying in it is really easy and intuitive.   </p>
          </div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind2.png' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind3.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind4.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-2"></div>
          <div className="col-12 col-sm-12 col-md-8 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind5.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-2"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-6 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind6.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-4 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind7.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind8.png' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-6 imageAbsoluteBottom"><img src='images/portfolio/details/1/Clean_Kind9.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>        
      </div>
      <MiddleMenu />

      
    </div>





  );
}

export default Homepage;
