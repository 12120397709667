import React, { useState, useEffect } from "react";
import Portfolio from "../Items/Portfolio";

/*const filters = [
  {
    id: 1,
    name: "All Projects",
  },
  {
    id: 2,
    name: "Branding",
  },
  {
    id: 3,
    name: "Digital",
  },
  {
    id: 4,
    name: "Design",
  },

];

const allData = [
  {
    id: 1,
    name: "Project 1",
    category: ["creative"],
    image: "images/portfolio/1.png",
    slug: "creative-art",
  },
  {
    id: 2,
    name: "Apple USB",
    category: ["creative", "design"],
    image: "images/portfolio/2.png",
    slug: "apple-usb",
  },
  {
    id: 3,
    name: "Work Space",
    category: ["branding"],
    image: "images/portfolio/3.png",
    slug: "work-space",
  },
  {
    id: 4,
    name: "Creative Bulb",
    category: ["creative"],
    image: "images/portfolio/4.png",
    slug: "creative-bulb",
  },
  {
    id: 5,
    name: "Iphone 8",
    category: ["branding", "art"],
    image: "images/portfolio/5.png",
    slug: "iphone-8",
  },
  {
    id: 6,
    name: "Minimal Art",
    category: ["design", "creative"],
    image: "images/portfolio/6.png",
    slug: "minimal-art",
  },
  {
    id: 7,
    name: "Creative Art",
    category: ["creative"],
    image: "images/portfolio/1.png",
    slug: "creative-art",
  },
  {
    id: 8,
    name: "Apple USB",
    category: ["creative", "design"],
    image: "images/portfolio/2.png",
    slug: "apple-usb",
  },
  {
    id: 9,
    name: "Work Space",
    category: ["branding"],
    image: "images/portfolio/3.png",
    slug: "work-space",
  },
];*/

function Portfolios() {
/*  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    let targetFilter = e.target.value
      ? e.target.value.toLowerCase()
      : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);
    let tempData;
    if (targetFilter === filters[0].name.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter((data) => {
        return (
          data.category.includes(targetFilter) && data.id <= dataVisibleCount
        );
      });
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;

    if (tempCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].name.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        let items = getAllItems.filter((data) => {
          return data.category.includes(activeFilter) && data.id <= tempCount;
        });
        setVisibleItems(items);
      }
    }
  };*/

  return (

    <div className="row" id="works">
{/*      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.name.toLowerCase() === activeFilter
                ? "list-inline-item current"
                : "list-inline-item"
            }
            key={filter.id}
            onClick={handleChange}
          >
            {filter.name}
          </li>
        ))}
      </ul>*/}

{/*      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>*/}
        <div className="col-12 col-sm-12 col-md-2"></div>   
        <div className=" portfolio-wrapper col-12 col-sm-12 col-md-8">
          <div className="row">
            <div className="col-md-4 col-sm-12 grid-item" >
             <a href="/Cleankind"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Clean & Kind</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/Clean&Kind_dwo_agency.jpg' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 grid-item" >
              <a href="/Nudamarket"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Nuda Market</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/nuda_market_dwo_agency.gif' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 grid-item" >
              <a href="/Aurum"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Aurum</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/aurum-dwoagency.jpg' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-8 col-sm-12 grid-item" >
              <a href="/Nomads"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Nomads</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/nomads_dwoagency.gif' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 grid-item" >
              <a href="/Yogaone"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Project 5</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/yogaone_dwoagency.jpg' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 grid-item" >
              <a href="/Gsd"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Project 6</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/gsd-diseno-web.jpg' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>   

            <div className="col-md-4 col-sm-12 grid-item" >
              <a href="/Inolvidables"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Project 7</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/inolvidables.jpg' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 grid-item" >
              <a href="/Goikid"/*{`works/1/creative-art`}*/>
                <div className="portfolio-item">
                  <div className="details">
                    <h4 className="title">Project 8</h4>
                  </div>
                  <span className="plus-icon">+</span>
                  <div className="thumb">
                    <img src='images/portfolio/GoiKid.jpg' alt='' />
                    <div className="mask"></div>
                  </div>
                </div>
              </a>
            </div>
 
            </div>                           
        </div>
        <div className="col-12 col-sm-12 col-md-2"></div>   
      
    </div>
    
  );
}

export default Portfolios;
