import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";






function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
   <div className="portfolioMainSection"> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10 ">
            <img src='images/portfolio/details/4/Nomads1.gif' alt='Branding and naming creative website agency' />

          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row details-container">
          <div className="col-12 col-sm-12 col-md-1"></div>
            <div className="col-12 col-sm-12 col-md-3 ">
              <h2> What we've done: </h2>
              <ul className="services-list">
                <li> Branding</li>
                <li> Web Design </li>
                <li> Web Development <br/>(current phase) </li>
                <li> Copywriting </li>
                <li> Art Direction</li>
              </ul>              

            </div>
            <div className="col-12 col-sm-12 col-md-7 ">
              <p>Nomads Guest House is new hotel in the beautifull city of Girona. </p>
              <p>It is a contemporary refuge that invites guests to disconnect, immerse themselves in nature, and savor the silence and stillness. More than just a vacation home, Nomads Guest House is an inspiring hotel. The architecture is part of the culture of each place we visit, and Nomads Guest House is a reflection of this.

              </p>
              <p>We created the branding and all the creative line for this project, including the website. Photography and colors are essential pieces of the brand identity that we have used as visual support signals to create a uniform appearance across different brand touchpoints. For the photography, we sought a minimalist and contemporary tone that symbolizes movement and keeps nature and design in mind. We played with a palette of neutral colors that bring us closer to nature.
            </p>




            </div>
            <div className="col-12 col-sm-12 col-md-2"></div>
        </div>        


      </div>

        <div className="row rowGridSpaceMedium">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 imageAbsoluteBottom"><img src='images/portfolio/details/4/branding-and-website-design-nomad.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-6 ">
            <div className="row rowFullHeight">
              <div className="col-12 col-sm-12 col-md-12"><img src='images/portfolio/details/4/branding-naming-design-creative-agency.jpg' alt='Branding and naming creative website agency' /></div>
              <div className="col-12 col-sm-12 col-md-12 imageAbsoluteBottom "><img className="imageAbsoluteBottomPadRight" src='images/portfolio/details/4/branding-color-palette-website-design-creative-agency.jpg' alt='Branding and naming creative website agency' /></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10"><img className="imgFullWidth" src='images/portfolio/details/4/logo-design-website-desgin-agency.jpg' alt='Logo design, branding and naming creative website design agency' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-2"></div>
          <div className="col-12 col-sm-12 col-md-8"><img src='images/portfolio/details/4/branding-minimalist-design-website-agency.jpg' alt='Logo design, branding and naming creative website design agency' /></div>
          <div className="col-12 col-sm-12 col-md-2"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <div className="row rowRowMargin10">
              <div className="col-12 col-sm-12 col-md-4"><img src='images/portfolio/details/4/diseno-web-agencia-branding-logo-1.png' alt='Logo design, branding and naming creative website design agency' /></div>
              <div className="col-12 col-sm-12 col-md-4"><img src='images/portfolio/details/4/diseno-web-agencia-branding-logo-2.png' alt='Logo design, branding and naming creative website design agency' /></div>
              <div className="col-12 col-sm-12 col-md-4"><img src='images/portfolio/details/4/diseno-web-agencia-branding-logo-3.png' alt='Logo design, branding and naming creative website design agency' /></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace rowRowMargin10">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-1"></div>
              <div className="col-12 col-sm-12 col-md-3"></div>
              <div className="col-12 col-sm-12 col-md-8"><img src='images/portfolio/details/4/diseno-web-creatividad-agencia-digital-dwo.jpg' alt='Diseño web, diseño logo, diseño de marca, agencia digital' /></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>



        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5"><img src='images/portfolio/details/4/app-aplicaciones-movil-diseno-web-agencia.jpg' alt='Diseño web, diseño logo, diseño de marca, agencia digital' /></div>
          <div className="col-12 col-sm-12 col-md-6"></div>
        </div>

        


        


      <MiddleMenu />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      
    </div>





  );
}

export default Homepage;
