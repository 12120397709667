import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import About from "../components/Sections/About";
import MiddleMenu from "../components/Sections/MiddleMenu";
import Portfolios from "../components/Sections/Portfolios";
import Contact from "../components/Sections/Contact";
import CookieConsent from "react-cookie-consent";




function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (

    <div className="homepageWrap">
      <Header />
      <About />
      <MiddleMenu />
      <Portfolios />
      <Contact />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      



<CookieConsent
  location="top"
  buttonText="OK!"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#000" }}
  buttonStyle={{ color: "#FFF", fontSize: "16px" }}
  expires={150}
>
  <p className="cookiesText">This website uses cookies to enhance the user experience. You can obtain further information in our <a target="_blank" href="https://dwwwo.com/privacy-policy">Privacy Policy</a></p>{" "}
</CookieConsent>


    </div>





  );
}

export default Homepage;
