import React, {useEffect} from "react";
import HeaderInner from "../components/Layout/Header-inner";
import MiddleMenuInner from "../components/Sections/MiddleMenu-inner";
import $ from 'jquery'


$(document).ready(function() {
  $('.about-inner-wrapper').parent('div').parent('body').addClass('blackBody');  

  $('.accordion-list > li > .answer').hide();
    
  $('.accordion-list > li').click(function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active").find(".answer").hide();
    } else {
      $(".accordion-list > li.active .answer").hide();
      $(".accordion-list > li.active").removeClass("active");
      $(this).addClass("active").find(".answer").show();
    }
    return false;
  });

});


function Demopage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-inner-wrapper collabsWrap ">
        <HeaderInner />
        <MiddleMenuInner />

        {/*   ABOUT*/}
        <div className="row border-white">
          <div className="col-12 col-md-2 col-sm-12"></div>   
          <div className="col-12 col-md-3 col-sm-12">
            <h2> Collaborations </h2>
          </div>
          <div className="col-12 col-md-5 col-sm-12">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 rowGridSpaceLarge">
                <a href="http://www.angelsoundstudio.com/" target="_blank" rel="noreferrer"><img src='images/collabs/angelsound.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></a>
                <p>Angel Sound Studio<br />Postproduction & music creation.</p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 rowGridSpaceLarge">
                <a href="https://www.larsgoos.com/" target="_blank" rel="noreferrer"><img src='images/collabs/largoos.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></a>
                 <p>Lars Goos<br />Film maker based in Barcelona.</p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 rowGridSpaceLarge">
                <a href="https://www.mariafarres.com/" target="_blank" rel="noreferrer"><img src='images/collabs/mariafarres.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></a>
                <p>Maria Farrés<br />Video & photography.</p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 rowGridSpaceLarge">
                <a href="https://gemmabusquets.com/" target="_blank" rel="noreferrer"><img src='images/collabs/gemma.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></a>
                <p>Gemma Busquets<br />Creative Designer.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2 col-sm-12"></div>          
        </div>






    </div>
  );
}

export default Demopage;
