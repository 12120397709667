import React, { useState } from 'react';
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from 'react-i18next';


const languages = [
    { value: '', text: "Options" },
    { value: 'en', text: "ENG" },
    { value: 'es', text: "ES" }
]

function About() {

    // It is a hook imported from 'react-i18next'
    const { t } = useTranslation(); 
  
    const [lang, setLang] = useState('en');
  
    // This function put query that helps to 
    // change the language
    const handleChange = e => { 
        setLang(e.target.value);
        let loc = "http://localhost:3000/";
        window.location.replace(loc + "?lng=" + e.target.value);
    }

  return (



      <div className="" id="about-us">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-2"></div>   
          <div className="col-12 col-sm-12 col-md-8">
{/*            <p><strong>DWO.</strong> We are an independent creative digital agency that effectively blends strategy, creative direction and 
            development to help you achieve your goals. We navigate in 
            the space between design and development, that place where reason and heart finally meet and align giving place to exciting, 
            effective and meaningful digital brands, websites and strategies that go hand in hand with your company's goals and DNA. 
            Call it the heart & the brain behind the brand, <strong>we create your brand's digital world.</strong> </p>   */}       

            <p> <strong>DWO.</strong> {t('intro-home')}</p>
            
{/*                <select value={lang} onChange={handleChange}>
                {languages.map(item => {
                    return (<option key={item.value} 
                    value={item.value}>{item.text}</option>);
                })}
            </select>*/}
          </div>

          <div className="col-12 col-sm-12 col-md-2"></div>          
        </div>
      </div>




  );
}

export default About;
