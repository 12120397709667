import React, {useEffect} from "react";
import HeaderInner from "../components/Layout/Header-inner";
import MiddleMenuInner from "../components/Sections/MiddleMenu-inner";
import ContactForm from "../components/Sections/Contact-form";
import $ from 'jquery'


$(document).ready(function() {
  $('.about-inner-wrapper').parent('div').parent('body').addClass('blackBody');  

  $('.accordion-list > li > .answer').hide();
    
  $('.accordion-list > li').click(function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active").find(".answer").hide();
    } else {
      $(".accordion-list > li.active .answer").hide();
      $(".accordion-list > li.active").removeClass("active");
      $(this).addClass("active").find(".answer").show();
    }
    return false;
  });

});


function Demopage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-inner-wrapper ">
        <HeaderInner />
        <MiddleMenuInner />

        {/*   ABOUT*/}
        <div className="row border-white">
          <div className="col-12 col-md-2 col-sm-12"></div>   
          <div className="col-12 col-md-3 col-sm-12">
            <h2> About </h2>
          </div>
          <div className="col-12 col-md-5 col-sm-12">
            <p><strong>DWO.</strong>  We're a team of passionate digital creatives who are dedicated to helping you elevate your brand in today's digital world.
            </p>
            <p>We believe in the power of collaboration, creativity, and innovation. We're here to help you bring your brand to life and stand out in a crowded marketplace. Whether you're a startup looking to establish your brand or an established business looking to refresh your digital presence, we're here to help.</p> 
            <p>And how we do that? Creating great brands, stories, effective websites and efficient digital strategies that will help your project achieve its goals. We are the brain behind the brand, the soul within the brain, we create your brand's digital world with a mix of detail, love, creativity and strategy. </p> 

            <p>We always use empathy as our first weapon to understand your needs and where do you want to go. We take a personalized approach to each project, working closely with you to 
            understand your unique needs and create a customized strategy that meets your goals. </p>
            <p>And together we will get there better and faster because we're not just your service provider, 
            we're your partner. We work with you every step of the way to ensure that we're meeting your needs and delivering results that exceed your expectations. We're dedicated to helping you achieve success and grow your business. </p>
            <p>We work for different industries, customers and friends and collab in nonprofit social causes we support :) </p>  

            <p>Founded by two, powered by many. <a className="colabsLink" href="/Collabs">See collabs -></a></p>
          </div>
          <div className="col-12 col-md-2 col-sm-12"></div>          
        </div>

       {/*   SERVICES*/}
        <div className="row border-white services-wrap">
          <div className="col-12 col-sm-12 col-md-2"></div>   
          <div className="col-12 col-sm-12 col-md-3">
            <h2> Services </h2>
          </div>
          <div className="col-12 col-sm-12 col-md-5">
            <ul>
              <li>Digital & Creative Brand Strategy </li>
              <li>Website development </li>
              <li>E-commerce development </li>
              <li>UX & UI Website & App design </li>
              <li>AI ChatGpt Api </li>
              <li>SEO and Web Positioning</li>
              <li>Social Media & Digital marketing</li>
              <li>Online Advertising Campaigns</li>
              <li>Metrics collection and analysis</li>
            </ul>
          
            <ul>
              <li>Logo design </li>
              <li>Visual Identity</li>
              <li>Art Direction</li>
              <li>Storytelling & Copywritting </li>
              <li>Branded content </li>
              <li>Graphic & Editorial Design </li>
              <li>Signage </li>
              <li>Events </li>             
            </ul>



        {/* <ul class="accordion-list">
            <li>
              <h3>Digital & Creative Brand Strategy</h3>
              <div class="answer">
                <p>What do you want to achieve with a new website or your website's redesign? What are your website's goals? Who is yout target? Knowing which audience we are targeting will play an important role in how the web should look and function.</p> 
                  
              </div>
            </li>
            <li>
              <h3>Web & Digital Design</h3>
              <div class="answer"><p>This will vary depending on the type of project. For logo & branding projects, fees will be one-time costs. For website projects, we will typically charge a monthly fee, depending on the level of service you choose. To view a full list of our monthly packages, click here.</p></div>
            </li>
            <li>
              <h3>How much does a logo cost?</h3>
              <div class="answer">
                <p>"Why so much?" many people ask. To answer simply - because they require a lot of work. At least, to do them properly. And of all of the investments that you will make for your business, your logo & branding is one area where you don't want to skimp.</p>
                
              </div>
            </li>
            <li>
              <h3>Digital Content Creation</h3>
              <div class="answer">
                <p>Experience, quality, and results.</p>
                <p><strong>Experience.</strong> We have been designing websites professional since the early days of the web - before CSS was mainstream, and people still used tables for their website layouts. In the 20+ years that we have been doing this, we have work with and learned from many of the top voices in several industries. To put it simply - there are very few people in the world, let alone the area that can top our years of experience.</p>
                
              </div>
            </li>
            <li>
              <h3>Storytelling & Copywriting</h3>
              <div class="answer">
                <p>We started this business from scratch, and know full-well how tight money can be when you are first starting out. We empathize with the struggles of new business owners, and therefore work diligently so that the new businesses that we work with succeed.</p>
                
                
              </div>
            </li>
            <li>
              <h3>SEO and Web Positioning</h3>
              <div class="answer">
                <p>The difference between pre-designed and custom sites is a lot like the difference between buying a home versus building a home.</p>
                
                  
              </div>
            </li>
            <li>
              <h3>Social Media Design & Planning</h3>
              <div class="answer">
                <p>Yes and no. With each client that we work with, we designate time to figure out what content clients will need to edit on a regular basis, and create tools to allow them to do that easily. For things such as news, articles, blogs, and portfolios, clients will have access to the Content Management System, and add/edit/delete those things as needed.</p>
                
              </div>
            </li>
            <li>
              <h3>Analysis of Data</h3>
              <div class="answer">
                <p>Yes and no. With each client that we work with, we designate time to figure out what content clients will need to edit on a regular basis, and create tools to allow them to do that easily. For things such as news, articles, blogs, and portfolios, clients will have access to the Content Management System, and add/edit/delete those things as needed.</p>
                
                  
              </div>
            </li>
            <li>
              <h3>Digital Maintenance</h3>
              <div class="answer">
                <p>Yes and no. With each client that we work with, we designate time to figure out what content clients will need to edit on a regular basis, and create tools to allow them to do that easily. For things such as news, articles, blogs, and portfolios, clients will have access to the Content Management System, and add/edit/delete those things as needed.</p>
                
                  
              </div>
            </li>                        
          </ul>*/}
          </div>
          <div className="col-12 col-sm-12 col-md-2"></div>          
        </div>

        {/*   TEAM*/}
        <div className="row border-white services-wrap">
          <div className="col-12 col-sm-12 col-md-2"></div>   
          <div className="col-12 col-sm-12 col-md-3">
            <h2> Executive team </h2>
          </div>
          <div className="col-12 col-sm-12 col-md-5">
            <div className="team-img">
              <img src='images/team/team.jpg' alt='' />
            </div>

            <div className="team-content">
              <h3><strong>Gina Monllau</strong></h3>
              <h4>Development & Digital Strategy</h4> 
              <p>Project Manager and Front-end Developer since 2009. Wide experience helping customers to create and mantain a 
              better code base for reusability for varying size and scope projects. Experience in driving projects fordwars as 
              the development team leader, facilitating projects from concept to launch. Passionate about all the digital 
              environment and the potential of it and always eager to tackle more complex scenarios and continue to find 
              ways to maximize user efficency for new and challenging projects. Background in diverse corporate environments 
              including direct marketing, health&care, retail, and communications industries.<br/><br/>
              <a href="https://www.linkedin.com/in/gina-monllau-28406882/?originalSubdomain=es" target="__blank"> >> Check Gina on LinkedIn + </a>
              </p>

              <h3><strong>Anaïs Pont</strong></h3> 
              <h4>Creativity and Content Strategy </h4>
              <p>Graphic Designer and Creative Director with more than 15 years of experience. 
              Anaïs is passionate about modern minimalist design that have memorable and powerfull messages; this 
              helps brands tell their story in a unique and genuine way.
              A brand is not just a logo and this is why she holds a multidisciplinary design training that includes every important aspect in branding creation: from branding, packaging and printing to websites and communication and digital marketing.
              She always takes a strategic and creative approach to each step of the process, aligning inspiration, business goals and experiences.<br/>
              <br/><a href="https://www.linkedin.com/in/ana%C3%AFs-pont-6495aa65/?originalSubdomain=es" target="__blank"> >> Check Anaïs on LinkedIn + </a></p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-2"></div>          
        </div> 


        {/*   LET'S TALK */}
        <div className="row border-white services-wrap">
          <div className="col-12 col-sm-12 col-md-2"></div>   
          <div className="col-12 col-sm-12 col-md-3">
            <h2> Let's talk </h2>
          </div>
          <div className="col-12 col-sm-12 col-md-5">
            <p>Gina Monllau<br/>
            Development & Digital Strategy <br/>
            <a href="mailto:gina@dwwwo.com">gina@dwwwo.com</a><br/>
            .<br/>
            Anaïs Pont <br/>
            Creative and Content Strategy <br/>
            <a href="mailto:anais@dwwwo.com">anais@dwwwo.com</a> <br/>
            --<br/>
            <a href="mailto:hello@dwwwo.com">hello@dwwwo.com</a><br/>
             +34 663 412 621 // +34 630 748 526 </p>

          </div>
          <div className="col-12 col-sm-12 col-md-2"></div>          
        </div>  

        {/*   CONTACT FORM */}
        <div className="row  services-wrap" id="contact">
          <div className="col-12 col-sm-12 col-md-2"></div>   
          <div className="col-12 col-sm-12 col-md-8">
            <ContactForm />

          </div>
          <div className="col-12 col-sm-12 col-md-2"></div>          
        </div>  

    </div>
  );
}

export default Demopage;
