import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";



function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <img src='images/portfolio/details/6/goikid.jpg' alt='agencia digital creativa, desrrollo de webs y de apps, branding' />
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row details-container">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
              <li> Web Design </li>
              <li> Web Development </li>
              <li> Story telling</li>
              <li> Art Direction</li>  
              <li> <a href="https://www.goikid.com/" target="_blank" rel="noreferrer"> > Visit site</a></li> 
            </ul>              
          </div>

          <div className="col-12 col-sm-12 col-md-7 ">
            <p>As a supply chain consulting company, Goikid understands the importance of efficiency and optimization. That's why they approached us to help them redesign their old website 
            to better reflect their brand and services. We took on the challenge and worked closely with the Goikid team to create a new, modern, and user-friendly website.</p>
            <p>Our first step was to create a new design that was clean, professional, and easy to navigate. We opted for a one-scroll page design to ensure that visitors could easily find the 
            information they needed without having to click through multiple pages. We also incorporated infographics to help visualize complex information and data, making it easier for visitors 
            to understand the services offered by Goikid.</p>
            <p>In addition to the design, we also ensured that the website had well-structured data. This helped with search engine optimization (SEO), ensuring that the website could be easily 
            found by potential clients searching for supply chain consulting services. Overall, we're proud of the work we did for Goikid and believe that their new website better reflects their 
            brand and services, while also improving the user experience for visitors. </p>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="SixtypxTop"> </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/6/goikid2.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 imageAbsoluteBottom"><img src='images/portfolio/details/6/goikid3.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-2"></div>
          <div className="col-12 col-sm-12 col-md-8 "><img src='images/portfolio/details/6/goikid4.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-2"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/6/goikid5.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-5 "><img src='images/portfolio/details/6/gokid6.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

      </div>
      <MiddleMenu />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      
    </div>





  );
}

export default Homepage;
