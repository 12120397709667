import React, { useEffect, useRef } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";
import "../js/videoscroll.js";



function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const videoEl1 = useRef(null);

  const attemptPlay = () => {
    videoEl1 &&
      videoEl1.current &&
      videoEl1.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

window.addEventListener('load', videoScroll);
window.addEventListener('scroll', videoScroll);

function videoScroll() {

  if ( document.querySelectorAll('video').length > 0) {
    var windowHeight = window.innerHeight,
        videoEl = document.querySelectorAll('video');

    for (var i = 0; i < videoEl.length; i++) {

      var thisVideoEl = videoEl[i],
          videoHeight = thisVideoEl.clientHeight,
          videoClientRect = thisVideoEl.getBoundingClientRect().top;

      if ( videoClientRect <= ( (windowHeight) - (videoHeight*.5) ) && videoClientRect >= ( 0 - ( videoHeight*.5 ) ) ) {
        thisVideoEl.play();
      } else {
        thisVideoEl.pause();
      }

    }
  }

}


  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <video 
              id="videoEl"
              style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
              
              loop
              muted
              controls
              poster="images/portfolio/details/8/portadavideo.jpg"
              alt="Agencia digital creativa Barcelona, Inolvidabels, Alzheimer"
              src={`${process.env.PUBLIC_URL}/images/portfolio/details/8/inolvidables.m4v`}             
              ref={videoEl1}
            />
            
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row details-container">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
              <li> Web Design </li>
              <li> Art Direction</li>
              <li> Video </li>
              <li> Copywriting & Story telling </li>                 
              <li> Photography</li>
              <li> <a href="https://www.inolvidables.es/" target="_blank" rel="noreferrer">Visit website</a></li>
            </ul>              
          </div>

          <div className="col-12 col-sm-12 col-md-7 ">
           <p> We had the opportunity to work on a project that aimed to help the families of people with Alzheimer's. The project was an online training program that aimed to equip the families with the necessary skills to take better care of their loved ones. We were responsible for the branding and the concept of the campaign. We wanted to create a brand that was approachable and conveyed a sense of warmth and empathy. Our aim was to make the families feel supported and empowered throughout the training program.
            </p>
            <p>We took great care in developing the concept of the campaign. We understood the importance of creating a program that was accessible, easy to understand, and engaging. We wanted to create a safe space for the families to learn and grow. We worked tirelessly to ensure that the families had a positive experience and felt that they had gained valuable knowledge and skills to improve their quality of life. It was a fulfilling experience to work on a project that had the potential to make a real difference in people's lives.</p>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="TwentypxTop"> </div>
        

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 "><img src='images/portfolio/details/8/inolvidables1.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-6 "><img src='images/portfolio/details/8/inolvidables2.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowGridSpaceMedium">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10 "><img src='images/portfolio/details/8/inolvidables3.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10 "><img src='images/portfolio/details/8/inolvidables4.jpg' alt='agencia digital creativa, desarrollo web y app, ecommerce, branding' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

      </div>
         
      <MiddleMenu />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      
    </div>





  );
}

export default Homepage;
