import React from "react";
import { Link } from "react-router-dom";


function Skills() {
  return (
        <div className=" middleMenuWrap ">
            <div className="row">
              <div className="col-6">              
                   <nav id="" className="nav-wrap-middleMenuWrap navPosLeft ">
                      <ul id="nav" className="nav ">
                         <li><a className="smoothscroll" href="https://www.instagram.com/wearedwo/" target="_blank"><small>INSTAGRAM</small></a></li>
                         <li><a className="smoothscroll" href="mailto:HELLO@DWWWO.COM"><small>HELLO@DWWWO.COM</small></a></li>
                      </ul>
                   </nav>
              </div>
              <div className="col-6">              
                   <nav id="" className="nav-wrap-middleMenuWrap navPosRight ">

                      <ul id="nav" className="nav ">
                         <li><a className="smoothscroll" href="/agente"><small>[ES] KIT DIGITAL</small></a></li>
                         <li><a className="smoothscroll" href="/privacy-policy"><small>PRIVACY POLICY</small></a></li>
                      </ul>
                   </nav>
              </div>            
            </div>
        </div>

  );
}

export default Skills;
