import React, { useEffect } from "react";
import Header from "../components/Layout/Header";
import MiddleMenu from "../components/Sections/MiddleMenu";




function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <div> 
      <div className="vertical-logo row">
        <div className="col-12 col-sm-12 col-md-1">       
          <ul>
            <li><a href="/#work">D<br/>W<br/>O </a></li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-11"></div>
      </div>

      <div className="works-detail-wrap">
        <Header />
        <h1> <a href="/">DWO</a></h1>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10"> <img src='images/portfolio/details/7/gsd-logo-design-diseno-logo.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowDescription">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-3 ">
            <h2> What we've done: </h2>
            <ul className="services-list">
              <li> Branding </li>
              <li> Web Design </li>
              <li> Copywriting </li>
              <li> Art Direction</li>
            </ul>              
          </div>
          <div className="col-12 col-sm-12 col-md-7 ">
            <p>We recently had the pleasure of working with GSD Health, a startup consultancy focused on making startups investable and successful in their own mission within the life sciences, healthcare, and healthtech industries.</p>
            <p>GSD Health provides a range of services tailored to different stages of a startup's journey, from inspiring and catalyst services to execution-focused and seasoned professional services.</p>
            <p>We worked closely with GSD Health to develop a brand identity that would reflect their global partner approach and focus on movement, growth, and scalability. We created custom symbols and graphics that communicated these key points and helped to establish a strong brand presence for GSD Health.</p>
            <p>To complement the visual elements of the brand, we selected a geometric sans serif typeface that balanced purity with warmth and functionality with conceptual rigor. The result was a typography system that had both unmistakeable character and broad appeal, reflecting the unique perspective and approach of GSD Health.</p>
          </div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4"><img src='images/portfolio/details/7/diseno-web-agencia-creativa-programacion-web.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-6"><img src='images/portfolio/details/7/gsd-logo-design-diseno-logo-2.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-4 imageAbsoluteBottom"><img src='images/portfolio/details/7/agencia-creativa-desarrollo-web.jpg' alt='' /></div>

          <div className="col-12 col-sm-12 col-md-6 imageAbsoluteBottom"><img src='images/portfolio/details/7/programacion-web-seo.jpg' alt='' /></div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>

        <div className="row rowGridSpace">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <div className="row rowRowMargin10">
              <div className="col-12 col-sm-12 col-md-4"><img src='images/portfolio/details/7/valores-1-agencia-web.png' alt='Logo design, branding and naming creative website design agency, desarrollo web, agencia creativa' /></div>
              <div className="col-12 col-sm-12 col-md-4 d-flex align-items-center"><img src='images/portfolio/details/7/valores-2-agencia-web.png' alt='Logo design, branding and naming creative website design agency, desarrollo web, agencia creativa' /></div>
              <div className="col-12 col-sm-12 col-md-4"><img src='images/portfolio/details/7/valores-3-agencia-web.png' alt='Logo design, branding and naming creative website design agency, desarrollo web, agencia creativa' /></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
        <div className="row rowGridSpace rowRowMargin10">
          <div className="col-12 col-sm-12 col-md-1"></div>
          <div className="col-12 col-sm-12 col-md-10">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-8"><img src='images/portfolio/details/7/agencia-creativa-digital.jpg' alt='Diseño web, diseño logo, diseño de marca, agencia digital' /></div>
              <div className="col-12 col-sm-12 col-md-4"></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1"></div>
        </div>
      
      </div>
      <MiddleMenu />

{/*      <Services />
      <Experiences />
      <PricingTable />
      <Testimonials />
      <Blogs />*/}
      
    </div>





  );
}

export default Homepage;
