import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useRouteMatch } from "react-router-dom";
import $ from 'jquery'; 
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaDribbble,
} from "react-icons/fa";

const headerData = {
  name: "Bako Doe",
  designation: "Web Developer",
  imageThumb: "/images/logo.png",
  social: {
    facebook: "https://facebook.com",
    twitter: "https://twitter.com",
    instagram: "https://www.instagram.com/",
    youtue: "https://www.youtube.com/",
    dribbble: "https://dribbble.com/",
  },
};

$( document ).ready(function() {
   $('.langLink').click(function(){
       $('.langWarn').show();
       $(this).hide();
   });


});


function Header({ toggleHeader, toggleHandler }) {
  const [currentPath, setCurrentPath] = useState("");
  const match = useRouteMatch();

  useEffect(() => {
    setCurrentPath(match.path);
  }, [match]);

  return (
    <>

        <div className="pinned pinned_top">
          <header id="home">
            <div className="row">
              <div className="col-6">              
                   <nav id="" className="nav-wrap navPosLeft">
                      <ul id="nav" className="nav">
                         <li><a className="smoothscroll" href="/About"><small>ABOUT</small></a></li>
                         <li><a className="smoothscroll" href="/#works"><small>WORKS</small></a></li>
                      </ul>
                   </nav>
              </div>
              <div className="col-6">              
                   <nav id="" className="nav-wrap navPosRight">

                      <ul id="nav" className="nav">
                         <li><a className="smoothscroll"><small className="langLink">ESP</small><p className="langWarn">MUY PRONTO :)</p></a></li>
                         <li><a className="smoothscroll" href="/#contact"><small>CONTACT</small></a></li>
                      </ul>
                   </nav>
              </div>            
            </div>
          </header>
        </div>


    </>
  );
}

export default Header;
