
import React, { useState } from 'react';
import { send } from 'emailjs-com';
import ContactForm from "./Contact-form";



const Contact = () => {

      return (

        <div className="bk-contact-area section-pb-xl bg_color--1 row" id="contact">

            <div className="col-12 col-sm-12 col-md-2"></div>    
            
            <div className="container col-12 col-sm-12 col-md-8">

              <ContactForm />
                
            </div>
            <div className="col-12 col-sm-12 col-md-2"></div>  

        </div>


      )
  }

export default Contact;

